"use client";

import { RotatingLines } from "react-loader-spinner";

export default function Loading() {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <RotatingLines
        visible={true}
        strokeColor="#197441"
        width="96"
        strokeWidth="1"
        animationDuration="1.5"
        ariaLabel="rotating-lines-loading"
      />
    </div>
  );
}
